import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { map, isEmpty } from 'lodash';
import {
  LeftMenu,
  Dropdown,
  MainHeader,
  MultiLevelDropdown,
} from '../../components';
import { parseJwt, getJobRole } from '../../utils/CommonFunctions';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { surveyCommentsService, storageService } from '../../services';
import {
  StaffTypes,
  WebStorageNames,
  SurveyTypes,
  MenuItems,
  CommentQuestionType,
  HeadTeacherAccess,
  Roles,
  QueryStringTokens,
  SortOrder,
  AccessPermissions,
} from '../../utils/Constants';
import { RestrictedActions, IsActionAllowed } from '../../utils';
import {
  DateFormatWithMonth,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import { Carousel } from 'react-responsive-carousel';
import Highlight from 'react-highlight';
import { navigationActions } from '../../store/headerStore';
import {
  DownloadButtonStyle,
  AssistanceWrapperHeading,
} from '../../resources/styling/appStyle';
import {
  searchIcon,
  newMessage,
  adminReplied,
  staffReplied,
  iconDownload,
} from '../../resources/images/index';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { routesObj } from '../../routes';
import SurveyListDropDown from '../../components/common/SurveyListDropDown';
import { commentsActions } from '../../store/commentsStore';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { loaderAction } from '../../store/loaderStore';
import {
  ExtendedAssistanceWrapper,
  CommentsMainWrapper,
  ExtendedSearchFilterWrapper,
  SurveyExtendedSubContainer,
} from './SurveyCommentStyle';
import AccessLevel from '../../components/common/AccessLevel';

const questions = {
  first:
    'What is your opinion of staff wellbeing? What is going well and what could be improved?',
  second:
    'What one thing would have the biggest impact on improving your wellbeing and that of other staff?',
};

const additionalQestions = [
  {
    QuestionText:
      'What is your opinion of staff wellbeing? What is going well and what could be improved?',
    Order: 36,
  },
  {
    QuestionText:
      'What one thing would have the biggest impact on improving your wellbeing and that of other staff?',
    Order: 37,
  },
];

const staffOptions = [
  { value: StaffTypes.All, label: 'All Staff' },
  {
    value: StaffTypes.Teaching,
    label: 'Teaching Staff',
  },
  {
    value: StaffTypes.Support,
    label: 'Support Staff',
  },
  {
    value: StaffTypes.Leader,
    label: 'Leader',
  },
];

class surveyComments extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.state = {
      comments: [],
      message: 'Loading...',
      searchValue: '',
      selectedOption: { value: StaffTypes.All, label: 'All Staff' },
      sortingSelectedOption: { value: SortOrder.Newest, label: 'Latest First' },
      currentQuestion: 1,
      currentSlide: 0,
      isSearchDelete: false,
      latestSurveyOption: { value: '', label: 'Latest Survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest Survey' }],
      isRole: userInfo.role,
      isNewMessage: 'false',
      isFlag: false,
      accessLevel: 0,
      isReplayAllowed: false,
      isFlagAllowed: false,
      isPremium: false,
      additionalQuestions: [],
      showDownloadOption: false,
      anchorEl: false,
    };
  }

  componentDidMount() {
    this.getSurveyCommentsData();
    let sortingSelectedOption = storageService.getItem('sortingSelectedOption');
    if (sortingSelectedOption != undefined || sortingSelectedOption != null) {
      this.setState({ sortingSelectedOption: sortingSelectedOption });
    }
    amplitudeLog('PageView', 'Comments', '');
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
    window.scrollTo(0, 0);
    IsActionAllowed(RestrictedActions.ReplyComment)
      .then((response) => this.setState({ isReplayAllowed: response.data }))
      .catch();
    IsActionAllowed(RestrictedActions.FlagComment)
      .then((response) => this.setState({ isFlagAllowed: response.data }))
      .catch();
    IsActionAllowed(RestrictedActions.QuestionBank)
      .then((response) => {
        this.setState({ isPremium: response.data });
        if (response.data) {
          surveyCommentsService
            .getAdditionalQuestionsForComments(
              getQueryStringVal(QueryStringTokens.token)
            )
            .then((response) => {
              if (response.data != null) {
                if (response.data.length > 0) {
                  this.setState({
                    additionalQuestions: response.data,
                    currentQuestion: response.data[0].Order,
                  });
                } else {                 
                  this.setState({ additionalQuestions: additionalQestions,
                    currentQuestion: additionalQestions[0]?.Order
                     });
                }
              }
            })
            .catch((error) =>
              console.log('getting call failed with error: ', error)
            );
        } else {
        
          this.setState({ additionalQuestions: additionalQestions ,
            currentQuestion: additionalQestions[0]?.Order
            });
        }
      })
      .catch();
  }

  getSurveyCommentsData(token = null) {
    this.props.navigationActions.activeItemAction({
      item: MenuItems.Comments,
    });
    const surveyToken = token ? '?token=' + token : window.location.search;
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    surveyCommentsService
      .getSurveyComments(surveyToken, schoolToken)
      .then((response) => {
        const comments = response.success ? response.data : [];
        this.props.commentsActions.setSurveyComments(comments);
        this.setState({
          comments,
          message:
            'Your comments will be available after you launch your survey.',
        });
      })
      .catch((error) => console.log('getting call failed with error: ', error));
  }

  handleSearchChange = (e) => {
    let searchInputField = e.target.value;
    console.log(searchInputField);
    const searchText = searchInputField.trim();
    this.setState({ searchValue: searchText });
  };

  filterBySearchText = (searchText, comments) => {
    let { currentQuestion } = this.state;
    if (searchText) {
      comments = comments.filter((comment) => {
        if (
          comment.QuestionId === currentQuestion ||
          comment.CommentQuestionType === currentQuestion
        ) {
          return (
            comment.CommentText.toLowerCase().indexOf(
              searchText.toLowerCase()
            ) >= 0
          );
        }
      });
    }
    return comments;
  };

  handleJobRoleValueChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  filterByJobRole = (selectedOption, comments) => {
    return selectedOption.value === 0
      ? comments
      : comments.filter((item) => {
          if (selectedOption.value === StaffTypes.Teaching) {
            return (
              item.jobRole === StaffTypes.Teaching ||
              item.jobRole === StaffTypes.TeacherWorkingInside ||
              item.jobRole === StaffTypes.TeacherWorkingOutside ||
              item.jobRole === StaffTypes.AllTeaching
            );
          } else if (selectedOption.value === StaffTypes.Support) {
            return (
              item.jobRole === StaffTypes.Support ||
              item.jobRole === StaffTypes.SupportStaffWorkingInside ||
              item.jobRole === StaffTypes.SupportStaffWorkingOutside
            );
          } else if (selectedOption.value === StaffTypes.MiddleLeader) {
            return item.jobRole === StaffTypes.MiddleLeader;
          } else if (selectedOption.value === StaffTypes.SeniorLeader) {
            return item.jobRole === StaffTypes.SeniorLeader;
          } else if (selectedOption.value === StaffTypes.Leader) {
            return (
              item.jobRole === StaffTypes.MiddleLeader ||
              item.jobRole === StaffTypes.SeniorLeader
            );
          }
        });
  };
  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
    storageService.setItem('sortingSelectedOption', sortingSelectedOption);
  };

  filterSortByOrder = (sortingSelectedOption, comments) => {
    comments.sort((a, b) => {
      const firstDate = new Date(a.SubmissionDate);
      const secondDate = new Date(b.SubmissionDate);
      if (sortingSelectedOption.value === SortOrder.Newest) {
        return secondDate - firstDate; //newest sort
      }
      return firstDate - secondDate; //oldest sort
    });
  };
  filterByCommentReply = (sortingSelectedOption, comments) => {
    const { value, label } = sortingSelectedOption;
    let commentsAdmin = comments.filter((x) => x.AdminReplied);
    let commentsStaff = comments.filter((x) => x.StaffReplied);
    let commentsNew = comments.filter(
      (x) => !x.IsRead && !x.AdminReplied && !x.StaffReplied
    );
    let otherComments = comments.filter(
      (x) => x.IsRead && !x.AdminReplied && !x.StaffReplied
    );
    comments = commentsAdmin
      .concat(commentsStaff)
      .concat(commentsNew)
      .concat(otherComments);
    comments = comments.filter((item) => {
      if (value === SortOrder.NewMsg) {
        return !item.IsRead && !item.StaffReplied && !item.AdminReplied;
      }
      if (value === SortOrder.AllHeadTeachers) {
        return item.AdminReplied;
      }
      if (value === SortOrder.AllStaffReplies) {
        return item.StaffReplied;
      }
      if (value === SortOrder.MyMessages) {
        let userId = storageService.getItem(WebStorageNames.HeadTeacherId);
        return (
          item.HeadTeachers != null &&
          item.HeadTeachers.length > 0 &&
          (label === 'My Replies' ? item.AdminReplied : item.StaffReplied) &&
          item.HeadTeachers.find((x) => x == userId)
        );
      }
      if (value === SortOrder.NotReplied) {
        return !item.StaffReplied && !item.AdminReplied;
      }
    });
    return comments;
  };

  handleQuestionChange = (index) => {
    console.log(this.state.isPremium)
    this.setState({
      currentQuestion: this.state.isPremium
        ? this.state.additionalQuestions[index].Order
        : index + 1,
      currentSlide: index,
    });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  countComments() {
    const { comments } = this.state;
    const count1 = comments.reduce((n, comment) => {
      if (comment.CommentQuestionType === CommentQuestionType.BiggestImpact) {
        return n + 1;
      } else {
        return n;
      }
    }, 0);

    const count2 = comments.reduce((sum, comment) => {
      if (comment.CommentQuestionType === CommentQuestionType.StaffOpinon) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);
    const count = count1 + count2;
    return count;
  }

  handleInputFocus = () => {
    this.setState({ isSearchDelete: true });
  };
  handleInputBlur = () => {
    let { searchValue } = this.state;
    if (isEmpty(searchValue)) {
      this.setState({ isSearchDelete: false });
    }
  };

  handleInputValue = () => {
    this.setState({
      searchValue: '',
      isSearchDelete: false,
    });
  };

  printComments = () => {
    amplitudeLog('Comments', 'Download', '');
    let html =
      '<html><head><style>@media print{ ul{margin: 0; } li{ padding: 3px 5px 8px 0; } .question {margin: 20px 10px 5px 23px; }}</style></head>';

    let filteredComments = this.getFilteredComments();
    console.log(filteredComments);
    let filterStaffOpinion = filteredComments.filter((comment) => {
      if (comment.CommentQuestionType === CommentQuestionType.StaffOpinon) {
        return comment;
      }
    });
    let filterBiggestImpact = filteredComments.filter((comment) => {
      if (comment.CommentQuestionType === CommentQuestionType.BiggestImpact) {
        return comment;
      }
    });

    html += '<h2 class="question">' + questions.first + '</h2>';

    html += '<ul>';
    filterStaffOpinion.map((item, index) => {
      if (!isEmpty(item.CommentText))
        html += '<li key={index}>' + item.CommentText + '</li>';
    });
    html += '</ul>';

    html += '<h2 class="question">' + questions.second + '</h2>';

    html += '<ul>';
    filterBiggestImpact.map((item) => {
      if (!isEmpty(item.CommentText))
        html += '<li>' + item.CommentText + '</li>';
    });
    html += '</ul>';

    html += '</body></html>';

    var oldPage = document.body.innerHTML;
    document.body.innerHTML = html;
    window.print();
    document.body.innerHTML = oldPage;
    window.location.reload();
  };
  filterByImportant = (comments) => {
    return comments.filter((item) => item.ImportantFlag === true);
  };

  getFilteredComments = () => {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const {
      comments,
      selectedOption,
      searchValue,
      sortingSelectedOption,
      isFlag,
    } = this.state;
    let filterComments = comments;
    if (filterComments.length > 0) {
      if (sortingSelectedOption) {
        if (
          sortingSelectedOption.value == SortOrder.Newest ||
          sortingSelectedOption.value == SortOrder.Oldest
        )
          this.filterSortByOrder(sortingSelectedOption, filterComments);
        else
          filterComments = this.filterByCommentReply(
            sortingSelectedOption,
            filterComments
          );
      }

      if (isFlag) {
        filterComments = this.filterByImportant(filterComments);
      }
      if (searchValue) {
        filterComments = this.filterBySearchText(searchValue, filterComments);
      }
      if (selectedOption) {
        filterComments = this.filterByJobRole(selectedOption, filterComments);
      }
    }
    loaderActions.dataLoading(false);
    return filterComments;
  };

  importantFlag = (comment, flag) => {
    const { accessLevel } = this.state;
    if (accessLevel === HeadTeacherAccess.Full) {
      comment.importantFlag = flag;
      surveyCommentsService
        .setCommentAsImportant(comment)
        .then((response) => {
          const { comments } = this.state;
          let commentCopy = JSON.parse(JSON.stringify(this.state.comments));
          const index = comments.findIndex((com) => com.Id === comment.Id);
          commentCopy[index].ImportantFlag = flag;
          this.setState({ comments: commentCopy });
        })
        .catch((error) =>
          console.log('getting call failed with error: ', error)
        );
    }
  };

  replyComment = (comment) => {
    const { accessLevel } = this.state;

    if (accessLevel === HeadTeacherAccess.Full) {
      surveyCommentsService
        .setCommentIsRead(comment.Id)
        .then((response) => {
          const remainingNewComments =
            this.props.newCommentCount - 1 > 0
              ? this.props.newCommentCount - 1
              : 0;

          this.props.navigationActions.showNewCommentCount({
            item: remainingNewComments,
          });
        })
        .catch((error) =>
          console.log('getting call failed with error: ', error)
        );
    }

    this.props.commentsActions.setSelectedComment(comment);

    if (this.state.additionalQuestions !== null) {
      var question = this.state.additionalQuestions.find(
        (item) => item.Order === comment.QuestionId
      );
      this.props.commentsActions.setSelectedQuestion(question);
    }

    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);

    if (role === Roles.GroupDirector || schoolToken !== null) {
      let surveyToken = getQueryStringVal(QueryStringTokens.token);
      this.props.history.push(
        routesObj.HeadTeacherCommentsReply.path +
          '?token=' +
          surveyToken +
          '&schoolToken=' +
          schoolToken
      );
    } else this.props.history.push(routesObj.HeadTeacherCommentsReply.path);
  };

  getComment = (commentText, searchValue, comment) => {
    const isFlag = comment.ImportantFlag;
    const { isRole, accessLevel } = this.state;
    if (isEmpty(commentText)) return <></>;
    return (
      <>
        <div
          className={`searched-data-wrapper ${
            isFlag ? 'searched-data-wrapper-highlight' : ' '
          }`}
        >
          {!comment.IsRead &&
            !comment.AdminReplied &&
            !comment.StaffReplied && (
              <span className='new-message-label'>
                <img src={newMessage} alt='' />
              </span>
            )}
          {comment.AdminReplied && !comment.StaffReplied && (
            <span
              className='new-message-label'
              style={{ backgroundColor: '#45338C' }}
            >
              <img src={adminReplied} alt='' />
            </span>
          )}
          {!comment.AdminReplied && comment.StaffReplied && (
            <span
              className='new-message-label'
              style={{ backgroundColor: '#007A7A' }}
            >
              <img src={staffReplied} alt='' />
            </span>
          )}
          <div className='search-date'>
            {DateFormatWithMonth(comment.MaxReplyDate)}
            {getJobRole(comment.jobRole)}
          </div>
          <div className='search-heighlight'>
            {comment.SchoolStaffId != null ? (
              <Highlight
                style={
                  isRole !== 'GroupDirector' &&
                  accessLevel !== HeadTeacherAccess.View &&
                  this.state.isReplayAllowed
                    ? { cursor: 'pointer' }
                    : {}
                }
                search={searchValue}
                onClick={() =>
                  isRole !== 'GroupDirector' &&
                  accessLevel !== HeadTeacherAccess.View &&
                  this.state.isReplayAllowed &&
                  this.replyComment(comment)
                }
              >
                {commentText}
              </Highlight>
            ) : (
              <Highlight style={{ cursor: 'default' }} search={searchValue}>
                {commentText}
              </Highlight>
            )}
          </div>

          <span className='data-icons'>
            {!this.state.isFlagAllowed ? (
              <button className={`flag-icon disabled-flag-btn tooltip`}>
                <span className='tooltiptext'>
                  You can flag comments by upgrading your account.
                </span>
              </button>
            ) : isRole !== 'GroupDirector' &&
              accessLevel !== HeadTeacherAccess.View ? (
              comment.ImportantFlag ? (
                <button
                  className='active-flag'
                  onClick={() => this.importantFlag(comment, false)}
                ></button>
              ) : (
                <button
                  className='flag-icon'
                  onClick={() => this.importantFlag(comment, true)}
                ></button>
              )
            ) : (
              <button className={`flag-icon disabled-flag-btn tooltip`}>
                {isRole === 'GroupDirector' && (
                  <span className='tooltiptext'>
                    Only individual school leaders can flag staff comments.
                  </span>
                )}
              </button>
            )}
            {!this.state.isReplayAllowed ? (
              <button
                className={`unread-reply unread-reply-active disabled-replay-btn tooltip`}
              >
                <span className='tooltiptext'>
                  You can reply to comments by upgrading your account.
                </span>
              </button>
            ) : isRole !== 'GroupDirector' &&
              accessLevel !== HeadTeacherAccess.View ? (
              !comment.IsRead &&
              !comment.AdminReplied &&
              !comment.StaffReplied ? (
                <button
                  className={`unread-reply unread-reply-active`}
                  onClick={() => this.replyComment(comment)}
                ></button>
              ) : (
                <button
                  className={`reply-icon`}
                  onClick={() => this.replyComment(comment)}
                ></button>
              )
            ) : (
              <button
                className={`unread-reply unread-reply-active disabled-replay-btn tooltip`}
              >
                {isRole === 'GroupDirector' && (
                  <span className='tooltiptext'>
                    Only individual school leaders can reply to staff comments.
                  </span>
                )}
              </button>
            )}
          </span>
        </div>
      </>
    );
  };
  downloadSurveyComments = () => {
    this.setState({ showDownloadOption: false });
    const surveyToken = getQueryStringVal(QueryStringTokens.token);
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    surveyCommentsService
      .GetPDFSurveyComments(surveyToken, schoolToken)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Survey_Comments.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };
  downloadSurveyCommentsInExcel = () => {
    this.setState({ showDownloadOption: false });
    const surveyToken = getQueryStringVal(QueryStringTokens.token);
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    surveyCommentsService
      .GetExcelSurveyComments(surveyToken, schoolToken)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        let schoolName = document.getElementsByClassName(
          'dashboard-school-name'
        )[0].children[0].innerText;
        link.setAttribute('download', schoolName + '.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };
  render() {
    const {
      message,
      selectedOption,
      searchValue,
      sortingSelectedOption,
      currentQuestion,
      currentSlide,
      isSearchDelete,
      isFlag,
    } = this.state;

    let filterComments = this.getFilteredComments();
    
    return (
      <AccessLevel
        permissions={[
          AccessPermissions.Full,
          AccessPermissions.HeadTeacher,
          AccessPermissions.View,
        ]}
      >
        <CommentsMainWrapper>
          <div className='left-container'>
            <LeftMenu />
          </div>
          <div className='right-container'>
            <MainHeader />
            <Grid container>
              <Grid item lg={6} md={5} sm={5} xs={12}>
                <ExtendedAssistanceWrapper>
                  <AssistanceWrapperHeading>
                    <h2>Comments</h2>
                  </AssistanceWrapperHeading>
                </ExtendedAssistanceWrapper>
              </Grid>

              <Grid item lg={6} md={7} sm={7} xs={12}>
                <ExtendedAssistanceWrapper>
                  <div className='header-bottom'>
                    <div className='btn-download'>
                      <DownloadButtonStyle
                        onClick={(event) => {
                          this.setState({
                            showDownloadOption: true,
                            anchorEl: event.currentTarget,
                          });
                        }}
                      >
                        <img src={iconDownload} alt='' /> <span>Download</span>
                      </DownloadButtonStyle>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        open={this.state.showDownloadOption}
                        onClose={() => {
                          this.setState({ showDownloadOption: false });
                        }}
                      >
                        <MenuItem onClick={this.downloadSurveyComments}>
                          Pdf
                        </MenuItem>
                        <MenuItem onClick={this.downloadSurveyCommentsInExcel}>
                          Excel
                        </MenuItem>
                      </Menu>
                    </div>
                    <div className='dd-survey'>
                      <SurveyListDropDown />
                    </div>
                  </div>
                </ExtendedAssistanceWrapper>
              </Grid>

              <Grid item md={12} xs={12}>
                <SurveyExtendedSubContainer>
                  <ExtendedSearchFilterWrapper>
                    <div className='subcontainer-inner'>
                      <div className='search-filed'>
                        <img src={searchIcon} alt='search button' />
                        <input
                          id='searchInputField'
                          placeholder='Enter a keyword '
                          onChange={(e) => {
                            e.preventDefault();
                            this.handleSearchChange(e);
                          }}
                          icon='search'
                          className='searchField'
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          value={searchValue}
                        />
                        {isSearchDelete ? (
                          <div
                            className='cross'
                            onClick={this.handleInputValue}
                          >
                            X
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>

                      <div
                        className={`search-filters flag-filter ${
                          isFlag ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.setState({ isFlag: !this.state.isFlag });
                        }}
                      ></div>
                      <div className='search-filters'>
                        <Dropdown
                          name='StaffFilterr'
                          options={staffOptions}
                          selectedOption={selectedOption}
                          onChange={this.handleJobRoleValueChange}
                          value={selectedOption}
                        />
                      </div>
                      <div className='search-sorting'>
                        <MultiLevelDropdown
                          name='SortFilter'
                          selectedOption={sortingSelectedOption}
                          onChange={this.handleSortingValueChange}
                          value={sortingSelectedOption}
                        />
                      </div>
                    </div>
                  </ExtendedSearchFilterWrapper>

                  <div className='search-data-container'>
                    <div className='search-inner-container'>
                      <div className='search-inner-sub-container'>
                        <Carousel
                          autoPlay={false}
                          showThumbs={false}
                          showIndicators={false}
                          showStatus={false}
                          onChange={this.handleQuestionChange}
                          selectedItem={currentSlide}
                          className='carousel-outer'
                        >
                          {this.state.additionalQuestions.map((item) => (
                            <div className='slider-inner-wrapper'>
                              <span>Question {item.Order}</span>
                              {item.QuestionText}
                            </div>
                          ))}
                        </Carousel>
                        
                        {filterComments?.length > 0 ? (
                          filterComments.map((comment) =>
                            (!this.state.isPremium &&
                              currentQuestion ===
                                comment.CommentQuestionType) ||
                            (this.state.isPremium &&
                              currentQuestion === comment.QuestionId)
                              ? this.getComment(
                                  comment.CommentText,
                                  searchValue,
                                  comment
                                )
                              : ''
                          )
                        ) : (
                          <div className='searched-data-wrapper'>
                            <span className='no-message-text'>{message}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </SurveyExtendedSubContainer>
              </Grid>
            </Grid>
          </div>
        </CommentsMainWrapper>
      </AccessLevel>
    );
  }
}
const mapStateToProps = ({ header: { newCommentCount } }) => ({
  newCommentCount,
});

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  commentsActions: bindActionCreators(commentsActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(surveyComments));
