export const config = {
  BASE_URL: 'https://api.s.welbee.co.uk/api',
  //BASE_URL: 'https://api.s.welbee.co.uk/api',
  APP_URL: 'https://goog.welbee.co.uk/',
  GOOGLE_ANALYTICS_KEY: 'UA-140048454-1',
  AMPLITUDE_KEY: '83679fe6c6c59ed064ddfdde1da7bafa',
  HOTJAR: {
    hjid: '1317480',
    hjsv: '6'
  },
};
