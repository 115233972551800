import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme/colors';
import { AppModel } from '../../components';
import {
  schoolService,
  storageService,
  surveyService,
  surveyResultService,
} from '../../services';
import {
  WebStorageNames,
  Roles,
  HeadTeacherAccess,
} from '../../utils/Constants';
import { routesObj } from '../../routes';
import { navigationActions } from '../../store/headerStore';
import {
  amplitudeLog,
  DateFormat,
  parseJwt,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import { DateFormats, QueryStringTokens } from '../../utils/Constants';
import {
  ReadActionPlan,
  AppButton,
  FooterButtonsWrapper,
} from '../../resources/styling/appStyle';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import ReSendSurveyInvitation from './ReSendSurveyInvitation';
import SurveyLink from './SurveyLink';
import { toast } from 'react-toastify';
import {
  ExtendedReadActionPlan,
  ActiveSurveyWrapper,
  ResendSurveyLink,
} from './DashboardStyle';
import Alert from '@mui/material/Alert';
import AdditionalSurvey from '../additionalSurveys/AdditionalSurvey';
import moment from 'moment';
const { mandy, barleyWhite, purple } = colors;

const customStyles = {
  overlay: {
    background: 'rgba(255, 255,  255, 0.8)',
    //width: '100%',
  },
  modal: {
    background: `${barleyWhite}`,
    color: `${purple}`,
    //width: '100%',
  },
  closeIcon: {
    fill: `${purple}`,
  },
  closeIconSize: {
    width: 15,
    height: 15,
  },
};

export const SurveyPopupTypes = {
  ReSendInvitation: 1,
  SurveyCopyLink: 2,
};
class ActiveSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCloseSurveyOpen: false,
      isManualSendSurveyVisible: false,
      accessLevel: 0,
      showReSendSurveyInvitation: false,
      showSurveyLink: false,
      schoolStaff: '',
      IsSurveyStartDateReached: false,
      showSurveyNotStarted: false,
      showSurveyCopyLinkAlert: false,
      resendSurveyFormBusy: false,
      showInvitationSentTo: false,
      selectedStaff: [],
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.reSendsurveyInvitationHandler =
      this.reSendsurveyInvitationHandler.bind(this);

    this.surveyLinkHandler = this.surveyLinkHandler.bind(this);

    this.handleCloseSurvey = this.handleCloseSurvey.bind(this);
  }

  componentDidMount = () => {
    const token = storageService.getItem(WebStorageNames.Token);
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const userInfo = parseJwt(token);
    //this.isSurveyStarted();

    this.setState({
      isManualSendSurveyVisible: userInfo.role === Roles.SuperAdmin,
      accessLevel: accessLevel,
    });
  };

  isSurveyStarted = (value) => {
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);
    surveyService
      .IsSchoolSurveyLive(schoolId)
      .then((response) => {
        this.setState({ IsSurveyStartDateReached: response.data });
        if (value === SurveyPopupTypes.SurveyCopyLink) {
          this.state.IsSurveyStartDateReached
            ? this.setState({ showSurveyLink: true })
            : this.setState({ showSurveyCopyLinkAlert: true });
        } else if (value === SurveyPopupTypes.ReSendInvitation) {
          this.state.IsSurveyStartDateReached
            ? this.setState({ showReSendSurveyInvitation: true })
            : this.setState({ showSurveyNotStarted: true });
        }
      })
      .catch((error) => {
      });
  };

  clickHandler(e) {
    this.props.navigationActions.activeItemAction({
      item: 'report',
    });
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);

    if (role === Roles.GroupDirector || schoolToken !== null) {
      this.props.history.push(
        routesObj.SurveyReport.path +
        '?token=' +
        surveyToken +
        '&schoolToken=' +
        schoolToken
      );
    } else
      this.props.history.push(
        routesObj.SurveyReport.path + '?token=' + surveyToken
      );
    amplitudeLog('Dashboard', 'Report', '');
  }

  surveyLinkHandler(e) {
    this.isSurveyStarted(SurveyPopupTypes.SurveyCopyLink);
  }

  reSendsurveyInvitationHandler(e) {
    this.isSurveyStarted(SurveyPopupTypes.ReSendInvitation);
  }

  reSendSurveyEmailHandler(selectedStaffMembers) {
    this.setState({
      resendSurveyFormBusy: true,
      selectedStaff: selectedStaffMembers,
    });
    let selectedIds = [];
    //To handle All Selected
    if (selectedStaffMembers.some((x) => x.title.toLowerCase() === 'll')) {
      selectedIds.push(0);
    } else {
      selectedIds = selectedStaffMembers.map((item) => {
        return item.id;
      });
    }
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);

    const model = {
      surveyToken,
      selectedIds,
    };
    surveyResultService
      .reSendSurveyInvitation(model)
      .then((response) => {
        if (response.success) {
          this.setState(
            { showReSendSurveyInvitation: false, showInvitationSentTo: true },
            () => this.setState({ resendSurveyFormBusy: false })
          );
        }
      })
      .catch((error) => {
        this.setState({
          showReSendSurveyInvitation: true,
          resendSurveyFormBusy: false,
        });
      });
  }

  handleCloseSurvey() {
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    surveyService
      .closeSurveyWithToken(surveyToken, schoolToken)
      .then((response) => {
        this.setState({ isCloseSurveyOpen: false });
        const reportLink = '';
        if (schoolToken !== null && schoolToken !== '') {
          this.props.history.push(
            routesObj.SurveyReport.path +
            '?token=' +
            surveyToken +
            '&schoolToken=' +
            schoolToken
          );
        } else {
          this.props.history.push(
            routesObj.SurveyReport.path + '?token=' + surveyToken
          );
        }
      })
      .catch((error) => error);
  }

  renderManualSurveySendButton = (isManualSendSurveyVisible) => {
    const { activeSurveyDetails } = this.props;
    if (
      isManualSendSurveyVisible &&
      activeSurveyDetails !== '' &&
      !activeSurveyDetails.IsSurveyClosed
    ) {
      return (
        <span className='manual-survey-btn'>
          <button onClick={() => { }}>Manually send survey</button>
        </span>
      );
    }
  };

  render() {
    const { activeSurveyDetails, score } = this.props;
    const { accessLevel } = this.state;

    const activeSurvay =
      activeSurveyDetails.IsSurveyClosed ||
      (moment(activeSurveyDetails.StartDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD') &&
        moment(activeSurveyDetails.StartDate).format('YYYY-MM-DD') <=
        moment(activeSurveyDetails.EndDate).format('YYYY-MM-DD'));

    const pendingSurvay =
      moment(activeSurveyDetails.StartDate).format('YYYY-MM-DD') >
      moment().format('YYYY-MM-DD');

    const handleCloseSurveyClick = () => {
      activeSurveyDetails.SurveySubmission > 0
        ? this.setState({ isCloseSurveyOpen: true })
        : toast.error(
          'At least one survey submission is required to close the survey.'
        );
    };

    return (
      <ActiveSurveyWrapper
        value={`${activeSurveyDetails.RemainingDays
            ? activeSurveyDetails.RemainingDays.Percentage
            : 0
          }%`}
        isSurveyClosed={`${activeSurveyDetails.IsSurveyClosed ? '6px' : '15px'
          }`}
      >
        <h2>
          {activeSurvay
            ? pendingSurvay
              ? 'Pending Survey'
              : 'Latest Survey'
            : 'Active Survey'}
        </h2>

        {score !== 0 ? (
          <div>
            {/*<div className='week-heading'>
              <span className='weeks'>
                {activeSurveyDetails.RemainingDays
                  ? activeSurveyDetails.RemainingDays.RemainingTime
                  : ''}
              </span>
              <span className='deadline'>until deadline</span>
                </div>*/}
            <div
              className={`progress-wrapper ${score !== 0 ? 'progress-with-startdate' : ''
                }`}
            >
              <div className='progress-bar'>
                <div className='progress-fill' />
              </div>
              <span className='start'>
                {activeSurveyDetails.StartDate
                  ? DateFormat(
                    activeSurveyDetails.StartDate,
                    DateFormats.format_31_12_19
                  )
                  : 'start'}
              </span>
              {/*<span className='end'>
                {activeSurveyDetails.StartDate
                  ? DateFormat(
                    activeSurveyDetails.StartDate,
                      DateFormats.format_31_12_19
                    )
                  : 'end'}
                  </span>*/}
            </div>
            {!activeSurveyDetails.IsSurveyClosed ? (
              <div className='survey-description'>
                <span>
                  {activeSurveyDetails.SurveySubmission
                    ? activeSurveyDetails.SurveySubmission
                    : 0}
                </span>
                out of
                <span>
                  {activeSurveyDetails.EmployeesCount
                    ? activeSurveyDetails.EmployeesCount
                    : 0}
                </span>
                participants have completed the survey
                {accessLevel === HeadTeacherAccess.Full && (
                  <div>
                    <ResendSurveyLink>
                      <a
                        href
                        class='surveyLink'
                        onClick={this.reSendsurveyInvitationHandler}
                      >
                        Re-send survey invitation
                        <span class='toolTipText'>
                          Click to re-send the survey invitation to individual
                          or all staff in the Staff management list.
                        </span>
                      </a>
                      {this.state.showReSendSurveyInvitation
                        ? <ReSendSurveyInvitation
                          isOpen={this.state.showReSendSurveyInvitation}
                          onClose={() =>
                            this.setState({ showReSendSurveyInvitation: false })
                          }
                          reSendSurveyEmail={this.reSendSurveyEmailHandler.bind(
                            this
                          )}
                          isBusy={this.state.resendSurveyFormBusy}
                        /> : null
                      }

                    </ResendSurveyLink>
                    <ExtendedReadActionPlan>
                      <a
                        href
                        class='surveyLink'
                        onClick={this.surveyLinkHandler}
                      >
                        Survey Link
                        <span class='toolTipText'>
                          Click to copy the survey link for staff you cannot
                          invite personally using their email address. You will
                          not be able to respond to comments from staff that use
                          it.
                        </span>
                      </a>

                      <SurveyLink
                        isOpen={this.state.showSurveyLink}
                        onClose={() => this.setState({ showSurveyLink: false })}
                      />

                      <AppButton
                        className='close-survey-button'
                        color={mandy}
                        type='submit'
                        onClick={handleCloseSurveyClick}
                      >
                        Close Survey
                      </AppButton>
                      <ConfirmPopupForm
                        isOpen={this.state.isCloseSurveyOpen}
                        onOk={this.handleCloseSurvey}
                        onClose={() =>
                          this.setState({ isCloseSurveyOpen: false })
                        }
                        successPopupMessage='Are you sure you want to close the survey and view the report?'
                      />
                      <ConfirmPopupForm
                        isOpen={this.state.showSurveyNotStarted}
                        onOk={() =>
                          this.setState({ showSurveyNotStarted: false })
                        }
                        onClose={() =>
                          this.setState({ showSurveyNotStarted: false })
                        }
                        successPopupMessage={
                          <Alert
                            style={{ backgroundColor: 'rgb(255, 245, 204)' }}
                            severity='warning'
                          >
                            {
                              <strong>
                                You will be able to re-send survey invitations
                                once the survey is open.
                              </strong>
                            }
                          </Alert>
                        }
                      />
                      <ConfirmPopupForm
                        isOpen={this.state.showSurveyCopyLinkAlert}
                        onOk={() =>
                          this.setState({ showSurveyCopyLinkAlert: false })
                        }
                        onClose={() =>
                          this.setState({ showSurveyCopyLinkAlert: false })
                        }
                        successPopupMessage={
                          <Alert
                            style={{ backgroundColor: 'rgb(255, 245, 204)' }}
                            severity='warning'
                          >
                            {
                              <strong>
                                You will be able to copy the survey link once
                                the survey is open.
                              </strong>
                            }
                          </Alert>
                        }
                      />
                    </ExtendedReadActionPlan>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className='survey-completion'>
                  {activeSurveyDetails.calculateSubmissionPercentage > 100
                    ? 100
                    : activeSurveyDetails.calculateSubmissionPercentage}
                  % Participation ({activeSurveyDetails.SurveySubmission} Staff
                  Members)
                  <ExtendedReadActionPlan>
                    <button onClick={this.clickHandler}>View report</button>
                    {/* <div className='survey-button-wrapper create-survey-button-wrapper'>
                    <CreateSurveyButton />
                  </div> */}
                  </ExtendedReadActionPlan>
                </div>
                <AdditionalSurvey />
              </div>
            )}
          </div>
        ) : (
          <Fragment />
        )}
        <AppModel
          styles={customStyles}
          open={this.state.showInvitationSentTo}
          onClose={() => {
            this.setState({ showInvitationSentTo: false });
          }}
          bodyText={
            <div>
              <Row>
                <Col>
                  <h2>Survey invitations sent to following staff</h2>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.state.selectedStaff.map((item) => (
                    <div>{item.title}</div>
                  ))}
                </Col>
              </Row>
            </div>
          }
          footer={
            <FooterButtonsWrapper>
              <AppButton
                color={purple}
                type='button'
                onClick={() => {
                  this.setState({ showInvitationSentTo: false });
                }}
                style={{
                  cursor: 'pointer',
                  float: 'right',
                }}
              >
                Close
              </AppButton>
            </FooterButtonsWrapper>
          }
        ></AppModel>
      </ActiveSurveyWrapper>
    );
  }
}

const mapStateToProps = ({ header: activeItem }) => ({
  activeItem,
});

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActiveSurvey));
